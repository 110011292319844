<ng-container *ngIf="currentUser.isLoggedIn$ | async; else guestMenuItems">
    <a class="admin-link" mat-menu-item routerLink="/admin" *ngIf="currentUser.hasPermission('admin.access') && ( currentUser.get('email') == 'suporte@meugencia.com.br' || currentUser.get('email') == 'daniel.carrasco@m3idx.com.br') " (click)="onItemClick()">
        <mat-icon class="mat-icon" svgIcon="dashboard"></mat-icon>
        <span trans>Admin Area</span>
    </a>

    <a class="notifications-link" mat-menu-item routerLink="/notifications" *ngIf="forceNotifButton || ((breakpoints.isMobile$ | async) && config.get('notifications.integrated'))" (click)="onItemClick()">
        <mat-icon svgIcon="notifications"></mat-icon>
        <span trans>Notifications</span>
    </a>

    <ng-content></ng-content>

    <ng-container *ngIf="!hideConfigItems">
        <ng-container *ngFor="let item of config.get('vebto.navbar.dropdownItems')">
            <a mat-menu-item [routerLink]="item.route" *ngIf="shouldShowMenuItem(item)">
                <mat-icon [svgIcon]="item.icon"></mat-icon>
                <span trans>{{item.name}}</span>
            </a>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #guestMenuItems>
    <a routerLink="/login" mat-menu-item trans (click)="onItemClick()">Login</a>
    <a routerLink="/register" mat-menu-item *ngIf="!config.get('registration.disable') && !hideRegisterButton" (click)="onItemClick()" trans>Register</a>
</ng-template>
